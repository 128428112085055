import { SOCIAL_SHARING_FACEBOOK, SOCIAL_SHARING_TWITTER, SOCIAL_SHARING_LINKED_IN, } from '../constants/social-sharing';
import { urijs } from '../urijs';
var WIDTH = 640;
var HEIGHT = 640;
var openShareDialog = function (url) {
    window.open(url, 'Share', "width=" + WIDTH + ",height=" + HEIGHT + ",toolbar=no,menubar=no,scrollbars=no,location=no,status=no");
};
var encodeUrlTwice = function (href) {
    var uri = new urijs(href);
    uri.pathname(encodeURI(encodeURI(uri.pathname())));
    return uri.href();
};
var getUrl = function (socialNetwork, href) {
    switch (socialNetwork) {
        case SOCIAL_SHARING_FACEBOOK:
            return "http://www.facebook.com/sharer/sharer.php?u=" + href;
        case SOCIAL_SHARING_TWITTER:
            return "https://twitter.com/share?url=" + encodeUrlTwice(href);
        case SOCIAL_SHARING_LINKED_IN:
            return "https://www.linkedin.com/shareArticle?mini=true&url=" + href;
        default:
            return;
    }
};
export default function share(type, path) {
    openShareDialog(getUrl(type, path));
}
