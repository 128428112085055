import PropTypes from 'prop-types';
import React from 'react';
import { flowRight } from 'lodash';
import { connect } from '../runtime-context';
import Link from '../link/internal-link';
import { getCurrentSitePageTitle } from '../../store/basic-params/basic-params-selectors';
import withTranslate from '../../hoc/with-translate';
import styles from './header-title.scss';

export const HeaderTitle = ({ title, to, t }) => {
  const Component = to === undefined ? 'span' : Link;
  const translatedTitle = {
    'Blog': t('blog-mobile-header.title.blog'),
    'Post': t('blog-mobile-header.title.post'),
    'Category': t('blog-mobile-header.title.category')
  }[title] || title;

  return (
    <p className={styles.title}>
      <Component to={to}>{translatedTitle}</Component>
    </p>
  );
};

HeaderTitle.propTypes = {
  title: PropTypes.string,
  to: PropTypes.string,
  t: PropTypes.func,
};

const mapStateToProps = (state) => ({
  title: getCurrentSitePageTitle(state),
});

export default flowRight(connect(mapStateToProps), withTranslate)(HeaderTitle);
