import { readableUrl } from "@wix/communities-blog-client-common";

export const getPathWithoutSectionUrl = ({
  route,
  topology,
  shouldNavigateToFeed,
  useReadableUrl
}) => {
  const { sectionUrl, postPageSectionUrl, baseUrl } = topology;
  let currentSectionUrl = '';
  if (useReadableUrl) {
    const readableBaseUrl = readableUrl(baseUrl);
    currentSectionUrl = shouldNavigateToFeed
    ? readableBaseUrl === route
      ? readableBaseUrl
      : readableUrl(sectionUrl)
    : readableUrl(postPageSectionUrl);
  } else {
    currentSectionUrl = shouldNavigateToFeed
      ? baseUrl === route
        ? baseUrl
        : readableUrl(sectionUrl)
      : readableUrl(postPageSectionUrl);
  }
  return route.startsWith(currentSectionUrl)
    ? route.slice(currentSectionUrl.length)
    : route;
};
