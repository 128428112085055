import { get, identity, pickBy } from 'lodash';
var throwRequired = function (name) {
    throw new Error(name + " is required");
};
export var parseSiteFont = function (fontObj, _a) {
    var onlyFamily = _a.onlyFamily;
    var font = fontObj.value;
    if (onlyFamily) {
        var fontFamily = fontObj.family || fontObj.fontFamily || '';
        var fontFamilyIdx = -1;
        if (fontFamily.includes(' ')) {
            fontFamilyIdx = fontObj.value.indexOf("\"" + fontFamily + "\"");
        }
        if (fontFamilyIdx === -1) {
            fontFamilyIdx = fontObj.value.indexOf(fontFamily);
        }
        font = fontObj.value.substring(fontFamilyIdx);
    }
    if (font.slice(0, 5) === 'font:') {
        font = font.slice(5);
    }
    return font.replace(/[;]+$/g, '');
};
export var getSiteFont = function (appSettings, _a) {
    var wixParam = _a.wixParam, presetFallback = _a.presetFallback, fontFallback = _a.fontFallback, onlyFamily = _a.onlyFamily;
    if (!wixParam) {
        throwRequired('wixParam');
    }
    if (!presetFallback) {
        throwRequired('presetFallback');
    }
    if (!fontFallback) {
        throwRequired('fontFallback');
    }
    var font = get(appSettings, "style.fonts." + wixParam) ||
        get(appSettings, "textPresets." + presetFallback) || {
        value: fontFallback,
    };
    return parseSiteFont(font, { onlyFamily: onlyFamily });
};
/** Extract font properties from the `font` CSS attribute.
 * Regex takes a `font` string, for all its possibilities, and parses it to an object according to CSS specification.
 * https://www.w3.org/TR/CSS21/fonts.html#font-shorthand
 *
 * @example
 * `font: normal normal normal 40px/1.4em "open sans", sans-serif;`
 */
export var extractFontObjAttrs = function (fontObject) {
    var siteFont = parseSiteFont(fontObject, {});
    var regex = /^\s*(?=(?:(?:[-a-z]+\s*){0,2}(italic|oblique))?)(?=(?:(?:[-a-z]+\s*){0,2}(small-caps))?)(?=(?:(?:[-a-z]+\s*){0,2}(bold(?:er)?|lighter|[1-9]00))?)(?:(?:normal|\1|\2|\3)\s*){0,3}((?:xx?-)?(?:small|large)|medium|smaller|larger|[.\d]+(?:%|in|[cem]m|ex|p[ctx]))(?:\s*\/\s*(normal|[.\d]+(?:%|in|[cem]m|ex|p[ctx])))?\s*(\S.+?\S)\s*$/i;
    var parts = regex.exec(siteFont);
    if (!parts) {
        return {};
    }
    var fontStyle = parts[1], fontVariant = parts[2], fontWeight = parts[3], fontSize = parts[4], lineHeight = parts[5], fontFamily = parts[6];
    return pickBy({
        fontStyle: fontStyle,
        fontVariant: fontVariant,
        fontWeight: fontWeight,
        fontSize: fontSize,
        lineHeight: lineHeight,
        fontFamily: fontFamily,
    }, identity);
};
