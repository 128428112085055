var getDashboardUrl = function (metaSiteId) {
    return "https://manage.wix.com/dashboard/" + metaSiteId + "/blog";
};
export var editPostInDashboard = function (_a) {
    var postId = _a.postId, metaSiteId = _a.metaSiteId;
    return window.open(getDashboardUrl(metaSiteId) + "/" + postId + "/edit", '_blank', 'noopener');
};
export var createPostInDashboard = function (_a) {
    var metaSiteId = _a.metaSiteId, lang = _a.lang;
    return window.open(getDashboardUrl(metaSiteId) + "/create-post" + (lang ? "?lang=" + lang : ''), '_blank', 'noopener');
};
