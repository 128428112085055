import { get } from 'lodash';
export var getTPASettings = function (state) { return state.tpaSettings; };
export var getTPASettingsData = function (state) {
    return get(getTPASettings(state), 'settings', {});
};
export var getTPASettingsTranslations = function (state, language) {
    return get(getTPASettings(state), ['translations', language], null);
};
export var getAllPostsLabelTranslation = function (state, language) {
    return get(getTPASettings(state), ['translations', language, 'allPosts'], null);
};
