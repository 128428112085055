import numeral from 'numeral';
var DEFAULT_LANG = 'en';
var FILE_NAMES = {
    cs: 'cs',
    da: 'da-dk',
    de: 'de',
    es: 'es',
    fi: 'fi',
    fr: 'fr',
    it: 'it',
    ja: 'ja',
    nl: 'nl-nl',
    no: 'no',
    pl: 'pl',
    pt: 'pt-pt',
    ru: 'ru',
    th: 'th',
    tr: 'tr',
};
export default function createFormatNumber(lang) {
    var numeralData;
    try {
        numeralData = require("numeral/languages/" + FILE_NAMES[lang]);
    }
    catch (e) { }
    if (lang !== DEFAULT_LANG && !numeralData) {
        return function (value) { return (typeof value === 'number' ? "" + value : value); };
    }
    numeral.language(lang, numeralData);
    return function (value) { return numeral(value).format(); };
}
