import { trimStart } from 'lodash';
import { getOuterUrl } from './get-outer-url';
import { readableUrl } from './uri';
export var encodeQuery = function (query) {
    return encodeURIComponent(query.toLowerCase().replace(/#/g, '.hash.'));
};
export var decodeQuery = function (query) {
    return decodeURIComponent(query).replace(/\.hash\./g, '#');
};
export var createHashtagPath = function (decoratedText, _a) {
    var _b = (_a === void 0 ? {} : _a).newPath, newPath = _b === void 0 ? false : _b;
    var hashtagSlug = trimStart(decoratedText, '#');
    var hashtag = newPath ? hashtagSlug : "#" + hashtagSlug;
    return readableUrl(newPath ? "/hashtags/" + hashtag : "/search/" + encodeQuery(hashtag));
};
export var createHashtagHref = function (decoratedText, sectionUrl, _a) {
    var _b = (_a === void 0 ? {} : _a).newPath, newPath = _b === void 0 ? false : _b;
    return getOuterUrl(createHashtagPath(decoratedText, { newPath: newPath }), sectionUrl);
};
export var createAMPHashtagHref = function (sectionUrl, _a) {
    var _b = (_a === void 0 ? {} : _a).newPath, newPath = _b === void 0 ? false : _b;
    return function (decoratedText) { return getOuterUrl(createHashtagPath(decoratedText, { newPath: newPath }), sectionUrl); };
};
