import { __assign, __spreadArrays } from "tslib";
import { reduce, get } from 'lodash';
import { TinyColor } from '@ctrl/tinycolor';
import { getPresetColor, getSiteColor } from '../../services/colors';
import { POST_TEXT_COLOR, POST_TITLE_COLOR, LINK_AND_HASHTAG_COLOR, } from '../../constants/wix-params';
export var getTextColorSchema = function (appSettings) {
    if (appSettings === void 0) { appSettings = {}; }
    var colors = [
        getSiteColor(appSettings, {
            wixParam: POST_TEXT_COLOR,
            presetFallback: 'color-5',
            colorFallback: '#00000',
        }),
        getSiteColor(appSettings, {
            wixParam: POST_TITLE_COLOR,
            presetFallback: 'color-5',
            colorFallback: '#00000',
        }),
        getSiteColor(appSettings, {
            wixParam: LINK_AND_HASHTAG_COLOR,
            presetFallback: 'color-8',
            colorFallback: '#00000',
        }),
        getPresetColor(appSettings, 'color-13'),
        getPresetColor(appSettings, 'color-18'),
        getPresetColor(appSettings, 'color-23'),
    ];
    return reduce(colors, function (schema, color, index) {
        schema["textColor" + index] = { color: toHexColor(color), index: index };
        return schema;
    }, {});
};
export var getButtonColors = function (appSettings) {
    if (appSettings === void 0) { appSettings = {}; }
    // colors used for ricos button templates
    var colors = {
        color1: getPresetColor(appSettings, 'color-1'),
        color7: getPresetColor(appSettings, 'color-7'),
        color8: getPresetColor(appSettings, 'color-8'),
    };
    return colors;
};
export var mergeUserAndSiteColors = function (userColors, colorSchema) {
    var siteColors = Object.values(colorSchema);
    return __spreadArrays(siteColors, userColors);
};
var toHexColor = function (color) { return new TinyColor(color).toHexString(); };
export var styleSelectionPredicate = function (colorScheme, richContent) { return function (inlineStyle) {
    return (colorScheme[inlineStyle] &&
        richContent.isHexColor(colorScheme[inlineStyle].color)) ||
        richContent.isHexColor(inlineStyle);
}; };
export var viewerCustomStyleFn = function (colorScheme, richContent) { return function (inlineStyle) {
    if (colorScheme[inlineStyle] &&
        richContent.isHexColor(colorScheme[inlineStyle].color)) {
        return { color: colorScheme[inlineStyle].color };
    }
    if (richContent.isHexColor(inlineStyle)) {
        return { color: inlineStyle };
    }
    return {};
}; };
export var customStyleFn = function (viewerCustomStyleFn, richContent) { return function (inlineStyles) {
    return reduce(inlineStyles.toArray(), function (styles, inlineStyle) { return (__assign(__assign({}, styles), viewerCustomStyleFn(inlineStyle, richContent))); }, {});
}; };
export var viewerCustomBackgroundStyleFn = function (colorScheme, richContent) { return function (inlineStyle) {
    var userColor = get(colorScheme, [inlineStyle, 'color']);
    if (richContent.isHexColor(userColor)) {
        return { backgroundColor: userColor };
    }
    if (richContent.isHexColor(inlineStyle)) {
        return { backgroundColor: inlineStyle };
    }
    return {};
}; };
