import React, { Suspense } from 'react';

import Loader from '../components/loader';

const withLazyImport = (importFn) => {
  const isSSR = typeof window === 'undefined';
  let Component;

  const Lazy = (props) => {
    if (isSSR) {
      return <Loader />;
    }

    if (!Component) {
      Component = React.lazy(importFn);
    }

    return (
      <Suspense fallback={<Loader />}>
        <Component {...props} />
      </Suspense>
    );
  };

  return Lazy;
};

export default withLazyImport;
